import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
class RouterViewUI extends Component {

    render() {
        // const {location}= this.props
        // const { pathname } = location;
        const {status} = this.props
        // const targetRouterConfig = routers.find(
        //     (item) => {
        //         return item.path.replace(/\s*/g,"") === pathname
        //     }
        // );
        console.log(status)
        if (!status){
                console.log('跳转登录登录')
                return <Redirect to="/Login" />;
        }else {
            return <Redirect to="/Home/user" />;
        }
    }
}
const  RouterView = connect(state=>({status:state.login}),{})(RouterViewUI)
export default RouterView;

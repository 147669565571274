import {set} from '../add'
const init = [];
function permisssion(prestate = init,action){
    const {type,data} = action;
    switch (type){
        case set:
            return data;
        default:
            return prestate;
    }
}
export default permisssion;

import {createStore, combineReducers, applyMiddleware} from 'redux'
import login from "./reducers/login";
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from "redux-thunk";
import permission from "./reducers/permission";
import user from "./reducers/user"
const persistConfig = {
    key: 'root',
    version:0,
    storage: storage,
    // debug: true,
    stateReconciler: autoMergeLevel2 // 查看 'Merge Process' 部分的具体情况
};
const allReducers = combineReducers({login,permission,user});
const myPersistReducer = persistReducer(persistConfig, allReducers);
const store = createStore(myPersistReducer,applyMiddleware(thunk));
export const persistor = persistStore(store)
export default store;

import {setUser} from '../add'
const init = {};
function user(prestate = init,action){
    const {type,data} = action;
    switch (type){
        case setUser:
            return data;
        default:
            return prestate;
    }
}
export default user;

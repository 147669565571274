import React, {Component, Suspense} from 'react';
import routers from "./config/routers";
import './App.less'
import {Route} from "react-router-dom";
import RouterView from "./components/RouterView";
import {offline} from "./redux/action/login";
import store from "./redux/store";
import {message} from "antd";

    class App extends Component {
    componentDidMount() {
        window.addEventListener('storage',()=>{

            store.dispatch(offline())
            return message.error("请不要擅自更改localstore")
        })
    }

    render() {
        return (
               <div>
                <Suspense fallback={<h1>loading...</h1>}>
            {

                routers.map(router  =>{
                    return <Route key={router.path} path={router.path} component={router.component}/>
                })
            }
                </Suspense>
                <RouterView/>
               </div>
        );
    }
}
export default App;

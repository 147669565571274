import {offline_A,online_A} from "../add";
const defaultlogin = false;
function login(prestate = defaultlogin, action) {
    const {type, data} = action
    console.log("type"+type)
    console.log("type"+data)
    switch (type) {
        case online_A:
            return true;
        case offline_A:
            return false
        default:
          return prestate;
    }
}
export default login;

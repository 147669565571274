import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import store from "./redux/store";
import {persistor} from './redux/store';
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/lib/integration/react';

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConfigProvider locale={zh_CN}>
                <App/>
                </ConfigProvider>
            </PersistGate>
        </Provider>
    </BrowserRouter>
    ,
    document.getElementById('root')
);
